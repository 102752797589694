import React from "react";
import { WortiseAdsSlot5 } from "./adsmanager";

export class TextoComponent extends React.Component {
    render() {
        return (
            <div className="app">
                <div className="container">
                    <div className="columns link-view">
                        <div className="has-text-justified is-offset-one-quarter-desktop">
                            <h3 className="is-size-3 blog has-text-white has-text-weight-bold has-text-right">
                                Mas afinal, o que é o <b className="is-size-2">What's Link?</b>
                            </h3>
                            <br></br>
                            <div className="has-text-white container-help container-news">
                                <p>
                                    O melhor gerador de link click to chat para WhatsApp, Sensacional para você criar
                                    link personalizado para seu WhatsApp! Aqui você pode gerar link curto, personalizado
                                    e totalmente grátis!
                                </p>
                                 
                                <p>
                                    Se você já usa o WhatsApp, sabe que é possível adicionar contatos ao compartilhá-los
                                    através de conversas e grupos ou diretamente através do número de telefone. Porém,
                                    existe uma forma ainda mais fácil de disponibilizar seu próprio contato à amigos,
                                    clientes, ou mesmo nas redes sociais: através de um link curto e personalizado do
                                    seu numero do WhatsApp com a ferramenta What's Link, capaz de direcionar pessoas
                                    diretamente à um chat com você, a 1 click.
                                </p>
                                <br></br>
                                <p>
                                    Com o What’s Link, você pode utilizar o recurso click to chat do WhatsApp e divulgar
                                    em várias redes sociais, e assim ter vários pedidos de clientes conversando com
                                    você, sem precisar ter seu numero gravado na agenda do celular dele tornando muito
                                    rápido o contato. Crie agora um link com seu contato do aplicativo WhatsApp, escolha
                                    uma mensagem pré-definida e compartilhe nas redes sociais para seu público. O What's
                                    Link é melhor gerador de Link para WhatsApp da web. Você pode também gerar um What's
                                    Link para o seu WhatsApp business!
                                </p>
                                <br></br>
                                <p>
                                    Gere seu What's Link em apenas 10 segundos, ou seja, transformar seu número em um
                                    link web, curto, personalizado com o seu nome ou da sua empresa, você pode criar
                                    anúncios sobre seu produto/serviço e no final deixar o link amigável convidando a
                                    pessoa chamar você no seu aplicativo WhatsApp via link com apenas 1 click. Vender
                                    pelo chat WhatsApp nunca foi tão prático!
                                </p>
                                <br></br>
                                <p>
                                    O WhatsApp é um dos aplicativos de mensagens mais populares no Brasil. Na verdade,
                                    como apontam dados apresentados pelo Messenger People, o 56% da população brasileira
                                    utiliza o aplicativo de mensagens todos os dias e a previsão para 2019 era de
                                    atingir pelo menos 77 milhões de usuários ativos. Com mais de 1,5 bilhão de usuários
                                    no mundo e cerca de 130 milhões de usuários no Brasil, o último número divulgado
                                    dava conta de uma base de cerca de 130 milhões de pessoas.
                                </p>
                                <br></br>
                                <p>
                                    O criador de links para o WhatsApp é uma ótima ferramenta para ações de marketing ou
                                    relacionamento. Com o link curto e personalizado para mensagens personalizadas do
                                    seu WhatsApp, você poderá utilizar em campanhas, redes sociais, email marketing,
                                    banners e etc. O bom de encurtar e personalizar links e mensagens do aplicativo
                                    WhatsApp é que funcionará no desktop e no mobile da mesma forma. Faça bom uso da
                                    ferramenta encurtadora de link do WhatsApp.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <WortiseAdsSlot5 />
                </div>
                <div className="container">
                    <div className="columns link-view">
                        <div className="has-text-justified is-offset-one-quarter-desktop">
                            <h3 className="is-size-3 blog has-text-white has-text-weight-bold has-text-right">
                                Como colocar o link do <b className="is-size-2">What's Link</b> no instagram?{" "}
                            </h3>
                            <br></br>
                            <div className="has-text-white container-help container-news">
                                <p>
                                    Basta criar o link What's Link, depois basta ir no seu aplicativo Instagram, no
                                    botão "EDITAR PERFIL", no campo "SITE" você deve colar o link que foi gerado aqui em
                                    nosso site, assim ficando um link clicável na bio do seu perfil do Insta. Agora
                                    criar link para seu WhatsApp com número celular clicável ficou muito fácil com nosso
                                    gerador, portanto crie o seu atalho personalizado com sua marca e comece a divulgar
                                    em suas redes sociais, assim aumentando as vendas e facilitando a vida de quem quer
                                    bater um falar com você ou consultar sobre produtos do seu negócio.
                                </p>
                                 
                                <p>
                                    Qualquer dúvida ou necessidade de edição do seu link, por favor entre em contato com
                                    nossa equipe no FALE CONOSCO que você encontra aqui abaixo em nosso site, fale com
                                    um consultor e retire qualquer dúvida ou solicite uma ajuda, estamos felizes por
                                    tê-lo como nosso usuário, lembrando que o serviço é totalmente grátis e sempre será!
                                </p>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="columns link-view">
                        <div className="has-text-justified is-offset-one-quarter-desktop">
                            <h3 className="is-size-3 blog has-text-white has-text-weight-bold has-text-right">
                                Pra que serve o <b className="is-size-2">What's Link</b>?{" "}
                            </h3>
                            <br></br>
                            <div className="has-text-white container-help container-news">
                                <p>
                                    O Encurtador de link What's Link foi pensado para facilitar a vida de quem usa o
                                    WhatsApp, sem destinção de nicho. A ferramenta é ideal para sua loja virtual
                                    e-commerce, redes sociais como a queridinha do momento tiktok, facebook, linkedin,
                                    instagram, e para agregadores de link como{" "}
                                    <a
                                        href="https://bcard.me/"
                                        target="_blank">
                                        Bcard
                                    </a>{" "}
                                    e{" "}
                                    <a
                                        href="https://linktree.com.br"
                                        target="_blank">
                                        Linketree
                                    </a>
                                    . Ideal para qualquer negócio online, sendo um atalho muito prático para qualquer
                                    pessoa que tem interesse em seu produto ou serviço, seja ele qual for, ou ate mesmo
                                    para uso pessoal, para que seus amigos se conectem a você de forma mais rápida,
                                    tornando bem mais fácil de memorizar seu What's Link que direciona para seu numero
                                    do WhatsApp, que lembrar seu numero de telefone, por ser um link curtinho, fácil de
                                    memorizar.
                                </p>
                                <p>
                                    Você pode usar a criatividade e fazer do seu link, um endereço curto e prático para
                                    qualquer pessoa falar com você, utilizando ate em cartões de visita da sua empresa,
                                    seja ela virtual ou física, sendo totalmente grátis e seguro você personalizar sua
                                    identidade na rede mais utilizada para envio de mensagens instantâneas, o WhatsApp.
                                </p>
                                <br></br>
                                <p>
                                    Não importa se você tem uma loja de roupas femininas, masculinas, infantil ou
                                    unissex, loja de celulares, loja de móveis planejados, material de construção, super
                                    mercado ou se você é um profissional como dentista, advogado, psicólogo, médico,
                                    arquiteto, engenheiro, mecânico, programador, digital influencer, ou se você quer
                                    apenas facilitar a vida dos seus amigos ao se comunicar com você, o gerador de link
                                    curto para WhatsApp é personalizado e perfeito para qualquer situação!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
