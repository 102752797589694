import React, { useEffect } from "react";


export function WortiseAdsAnchor() {
    useEffect(() => {
        window.googletag.cmd.push(function () {
            window.googletag.display("div-gpt-ad-1721846364567-0");
        });
    }, []);
    return <div id="div-gpt-ad-1721846364567-0"></div>;
}

export function WortiseAdsSlot2() { //Content2
    useEffect(() => {
        window.googletag.cmd.push(function () {
            window.googletag.display("div-gpt-ad-1721847869289-0");
        });
    }, []);
    return (
        <div
            id="div-gpt-ad-1721847869289-0"
            className="adswortisemanager"
            style={{
                minHeight: "90px",
                minWidth: "320px",
            }}></div>
    );
}

export function WortiseAdsSlot1() {
    useEffect(() => {
        window.googletag.cmd.push(function () {
            window.googletag.display("div-gpt-ad-1721847782739-0");
        });
    }, []);
    return (
        <div
            id="div-gpt-ad-1721847782739-0"
            className="adswortisemanager"
            style={{
                minWidth: "320px",
                minHeight: "90px",
            }}></div>
    );
}

export function WortiseAdsSlot3() {
    useEffect(() => {
        
        window.googletag.cmd.push(function () {
            window.googletag.display("div-gpt-ad-1721847954344-0");
        });
    }, []);
    return (
        <div
            id="div-gpt-ad-1721847954344-0"
            className="adswortisemanager"
            style={{
                minHeight: "90px",
                minWidth: "320px",
            }}></div>
    );
}

export function WortiseAdsSlot4() {
    useEffect(() => {
        window.googletag.cmd.push(function () {
            window.googletag.display("div-gpt-ad-1721846745055-0");
        });
    }, []);
    return (
        <div
            id="div-gpt-ad-1721846745055-0"
            className="adswortisemanager"
            style={{
                minWidth: "250px",
                minHeight: "250px",
            }}></div>
    );
}

export function WortiseAdsSlot5() {
    useEffect(() => {
        window.googletag.cmd.push(function () {
            window.googletag.display("div-gpt-ad-1721846799716-0");
        });
    }, []);
    return (
        <div
            id="div-gpt-ad-1721846799716-0"
            className="adswortisemanager"
            style={{
                //  style='min-width: 250px; min-height: 250px;'
                minWidth: "250px",
                minHeight: "250px",
            }}></div>
    );
}

export function WortiseAdsSlot8() {
    useEffect(() => {
        window.googletag.cmd.push(function () {
            window.googletag.display("div-gpt-ad-1721846853383-0");
        });
    }, []);
    return (
        <div
            id="div-gpt-ad-1721846853383-0"
            className="adswortisemanager"
            style={{
                //  style='min-width: 250px; min-height: 250px;'
                minWidth: "250px",
                minHeight: "250px",
            }}></div>
    );
}

export function WortiseAdsSlot9() {
    useEffect(() => {
        window.googletag.cmd.push(function () {
            window.googletag.display("div-gpt-ad-1721848202039-0");
        });
    }, []);
    return (
        <div
            id="div-gpt-ad-1721848202039-0"
            className="adswortisemanager"
            style={{
                minWidth: "120px",
                minHeight: "600px",
            }}></div>
    );
}

export function WortiseAdsSlot10() {
    useEffect(() => {
        window.googletag.cmd.push(function () {
            window.googletag.display("div-gpt-ad-1721848130108-0");
        });
    }, []);
    return (
        <div
            id="div-gpt-ad-1721848130108-0"
            className="adswortisemanager"
            style={{
                minWidth: "120px",
                minHeight: "600px",
            }}></div>
    );
}

export function WortiseAdsSlot11() {
    useEffect(() => {
        window.googletag.cmd.push(function () {
            window.googletag.display("div-gpt-ad-1721848299949-0");
        });
    }, []);
    return (
        <div
            id="div-gpt-ad-1721848299949-0"
            className="adswortisemanager"
            style={{
                minWidth: "120px",
                minHeight: "600px",
            }}></div>
    );
}

export function WortiseAdsSlotIntersticial() {
    useEffect(() => {
        window.googletag.cmd.push(function () {
            window.googletag.display("div-gpt-ad-1721847098981-0");
        });
    }, []);
    return (
        <div
            id="div-gpt-ad-1721847098981-0"
            className="adswortisemanager"></div>
    );
}

export class WortiseCore extends React.Component {
   render() {
    return (
        <div></div>
    )
   }
}
